import router from '@/router'
// import store from '@/store'
//
// router.beforeEach((to, from, next) => {
//   if(store.getters.GET_TOKEN) {
//     next()
//   } else {
//     if(to.path === '/login'){
//       next()
//     }else{
//       next('/login')
//     }
//
//   }
// })
export default router
