export function setNowDate(myDate) {
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let nowdata = yy + '-' + mm + '-' + dd;
  return nowdata;
}

export function setNowDateYear(myDate) {
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let nowdata = yy;
  return nowdata;
}



export function setNowDateNo(myDate) {
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let nowdata = yy + mm + dd;
  return nowdata;
}

export function setNowDateNoTwo(myDate) {
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let nowdata = yy + ' 年 ' + mm + ' 月 ' + dd + ' 日';
  return nowdata;
}

export function setNowTime() {
  let nowtime;
  let myDate = new Date()
  // console.log(myDate)
  let wk = myDate.getDay()
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  let week = weeks[wk]
  nowtime = hou + ':' + min;
  return nowtime;
}

export function setNowEek() {
  let noweek;
  let myDate = new Date()
  // console.log(myDate)
  let wk = myDate.getDay()
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  let weeks = ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
  let week = weeks[wk]
  noweek = week;
  return noweek;
}

export function setNowMonth() {
  let myDate = new Date()
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  return mm;
}

export function setNowMonth2() {
  let myDate = new Date()
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  return yy + '-' +mm;
}

export function setNowHou(val) {
  let hou = String(val.getHours() < 10 ? '0' + val.getHours() : val.getHours())
  return hou;
}

export function setNowTimes(myDate) {
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  let nowDate = yy + '-' + mm + '-' + dd
  let nowTime = hou + ':' + min
  return nowDate + " " + nowTime
}


export function setNowTimesInHole(myDate) {
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  let nowDate = yy + '-' + mm + '-' + dd
  let nowTime = hou + ':' + min
  return nowDate + " " + nowTime + ":" + sec
}

export function setNowTimesMaterial(myDate) {
  let yy = String(myDate.getFullYear())
  let mm = String(myDate.getMonth() + 1 < 10 ?  '0' + (myDate.getMonth() + 1) : myDate.getMonth() + 1 )
  let dd = String(myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate())
  let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  let nowDate = yy + mm + dd
  let nowTime = hou + min
  return nowDate + nowTime
}

export function minTime(min) {

  return Math.floor(min/60) + "时" + (min%60) + "分";
  }


export function getDateString(stamp) {
  let d = new Date(parseInt(stamp, 0));
  let month =
    d.getMonth() + 1 < 10 ? 0 + "" + (d.getMonth() + 1) : d.getMonth() + 1;
  let day = d.getDate() < 10 ? 0 + "" + d.getDate() : d.getDate();
  let hour = d.getHours() < 10 ? 0 + "" + d.getHours() : d.getHours();
  let minute =
    d.getMinutes() < 10 ? 0 + "" + d.getMinutes() : d.getMinutes();
  let second =
    d.getSeconds() < 10 ? 0 + "" + d.getSeconds() : d.getSeconds();
  let dateString =
    d.getFullYear() +
    "-" +
    month +
    "-" +
    day +
    " " +
    hour +
    ":" +
    minute +
    ":" +
    second;
  return dateString;
}

export function showSetNowTime() {
  let nowtime;
  let myDate = new Date()
  let hou = String(myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours())
  let min = String(myDate.getMinutes() < 10 ? '0' + myDate.getMinutes() : myDate.getMinutes())
  let sec = String(myDate.getSeconds() < 10 ? '0' + myDate.getSeconds() : myDate.getSeconds())
  nowtime = hou + ':' + min + ':' + sec;
  return nowtime;
}


export function getPreMonth(month1) {
  const arr = month1.split("-");
  const year = arr[0]; //获取当前日期的年份
  const month = arr[1]; //获取当前日期的月份

  let year2 = year;
  let month2 = parseInt(month) - 1;
  if (month2 === 0) {
    //1月的上一月是前一年的12月
    year2 = parseInt(year2) - 1;
    month2 = 12;
  }

  if (month2 < 10) {
    //10月之前都需要补0
    month2 = "0" + month2;
  }
  return year2 + "-" + month2;
}

export function uuid() {
  const s = [];
  let uuidA = '';
  const hexDigits = '0123456789abcdef';
  for (let i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }
  s[14] = '4';
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
  s[8] = s[13] = s[18] = s[23] = '-';

  uuidA = s.join('');
  return uuidA;
}



