<template>
  <div id="app">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import { provide } from 'vue'
export default {
  name: 'App',
  provide() {
    return {
      reload: this.reload
    }
  },
  setup(){
    provide('ec',echarts)//provide
  },
  data() {
    return {
      isRouterAlive: true,
    }
  },
  mounted() {
  },
  methods: {
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function() {
        this.isRouterAlive = true
      })
    }
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  background-color: #F2F2F2
}
</style>
