import Vue, {createApp} from 'vue'
import Vuex from 'vuex'
import state from './state'
import getters from './getters'
import mutations from './mutations'
import createPersistedState from 'vuex-persistedstate'
import App from "../App";
createApp(App).use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],
  state,
  getters,
  mutations
})
