import axios from 'axios'
import store from '@/store'
import {
    ElLoading,
    ElMessage
} from 'element-plus'
//正式环境
axios.defaults.baseURL = 'https://show.cr12cz.cn/czsczq'
//开发环境
//axios.defaults.baseURL = 'http://localhost:8082'
//测试环境
//axios.defaults.baseURL = 'http://121.5.73.51:8082'
export default {
    get(url, callback, params = {}) {
        let loadingInstance = ElLoading.service({
            lock: true,
            text: '拼命加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(255,255,255,0.5)'
        })
        axios.get(url, {
            params: params,
            headers: {
                token: store.getters.GET_TOKEN,
                'Authorization': 'APPCODE ef6d2fd3878543a7820f0b90b5051838'
            }
        }).then(response => {
            if (response.data.code !== 200) {
                ElMessage.error(response.data.message)
            } else {
                if (response.data.token) {
                    store.commit('SET_TOKEN', response.data.token)
                }
                //  ElMessage .success(response.data.message)
                callback(response.data)
            }
        }).catch(err => {
            //Message.error(err.message)
        }).finally(() => {
            loadingInstance.close()
        })
    },
    post(url, callback, params = {}) {
        let loadingInstance = ElLoading.service({
            lock: true,
            text: '拼命加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(255,255,255,0.5)'
        })
        const formData = new FormData()
        for (let key in params) {
            formData.append(key, params[key])
        }
        axios.post(url, formData, {
            headers: {
                token: store.getters.GET_TOKEN
            }
        }).then(response => {
            if (response.data.code !== 200) {
                ElMessage.error(response.data.message)
            } else {
                if (response.data.token) {
                    store.commit('SET_TOKEN', response.data.token)
                }
                //  ElMessage .success(response.data.message)
                callback(response.data)
            }
        }).catch(err => {
            //  ElMessage .error(err.message)
        }).finally(() => {
            loadingInstance.close()
        })
    },
    get2(url, callback, params = {}) {
        // let loadingInstance = ElLoading.service({
        //   lock: true,
        //   text: '拼命加载中',
        //   spinner: 'el-icon-loading',
        //   background: 'rgba(255,255,255,0.5)'
        // })
        axios.get(url, {
            params: params,
            headers: {
                token: store.getters.GET_TOKEN,
                'Authorization': 'APPCODE ef6d2fd3878543a7820f0b90b5051838'
            }
        }).then(response => {
            if (response.data.code !== 200) {
                ElMessage.error(response.data.message)
            } else {
                if (response.data.token) {
                    store.commit('SET_TOKEN', response.data.token)
                }
                //  ElMessage .success(response.data.message)
                callback(response.data)
            }
        }).catch(err => {
            //Message.error(err.message)
        }).finally(() => {
            //loadingInstance.close()
        })
    },
    post2(url, callback, params = {}) {
        const formData = new FormData()
        for (let key in params) {
            formData.append(key, params[key])
        }
        axios.post(url, formData, {
            headers: {
                token: store.getters.GET_TOKEN
            }
        }).then(response => {
            if (response.data.code !== 200) {
                ElMessage.error(response.data.message)
            } else {
                if (response.data.token) {
                    store.commit('SET_TOKEN', response.data.token)
                }
                //  ElMessage .success(response.data.message)
                callback(response.data)
            }
        }).catch(err => {
            //  ElMessage .error(err.message)
        }).finally(() => {
            //loadingInstance.close()
        })
    },
    postForm(url, callback, params = {}) {
        let loadingInstance = ElLoading.service({
            lock: true,
            text: '拼命加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(255,255,255,0.5)'
        })
        const formData = new FormData()
        for (let key in params) {
            formData.append(key, params[key])
        }
        axios.post(url, formData, {
            headers: {
                token: store.getters.GET_TOKEN,
                "Content-Type":"application/json"
            }
        }).then(response => {
            if (response.data.code !== 200) {
                ElMessage.error(response.data.message)
            } else {
                if (response.data.token) {
                    store.commit('SET_TOKEN', response.data.token)
                }
                //  ElMessage .success(response.data.message)
                callback(response.data)
            }
        }).catch(err => {
            //  ElMessage .error(err.message)
        }).finally(() => {
            loadingInstance.close()
        })
    }
}
