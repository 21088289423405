export default {
  TOKEN: '',
  TOKEN_CAR: '',
  MENU: '',
  USER: '',
  USERID: '',
  BDST: '',
  POSION: '',
  DEPART: '',
  DEPARTFLAG: ''
}
