import { createApp } from 'vue'
import App from './App.vue'
import store from './store'
import axios from '@/utils/axiosutil.js'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import router from '@/utils/routerFilter'
import vueTencentCaptcha from '@carpenter/vue-tencent-captcha';
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
import dayjs from 'dayjs'
import "dayjs/locale/zh";
import vueSeamlessScroll  from "vue-seamless-scroll";
import * as echarts from 'echarts';
import vueEsign from 'vue-esign'



let weekOfYear = require('dayjs/plugin/weekOfYear')
dayjs.extend(weekOfYear)

const app = createApp(App)
app.config.globalProperties.axios = axios
app.config.globalProperties.$echarts = echarts
dayjs.locale("zh");
app.use(store).use(dayjs).use(ElementPlus, {locale: zhCn,}).use(VXETable)
    .use(router).use(vueSeamlessScroll ).use(vueTencentCaptcha).use(vueEsign).mount('#app')
router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
