export default {
  GET_TOKEN (state) { return state.TOKEN },
  GET_TOKEN_CAR (state) { return state.TOKEN_CAR },
  GET_MENU (state) { return state.MENU },
  GET_USER (state) { return state.USER },
  GET_USERID (state) { return state.USERID },
  GET_BDST (state) { return state.BDST },
  GET_DEPART (state) { return state.DEPART },
  GET_POSION (state) { return state.POSION },
  GET_DEPARTFLAG (state) { return state.DEPARTFLAG }
}
