export default {
  SET_TOKEN (state, val) { state.TOKEN = val },
  SET_TOKEN_CAR (state, val) { state.TOKEN_CAR = val },
  SET_MENU (state, val) { state.MENU = val },
  SET_USER (state, val) { state.USER = val },
  SET_USERID (state, val) { state.USERID = val },
  SET_BDST (state, val) { state.BDST = val },
  SET_DEPART (state, val) { state.DEPART = val },
  SET_POSION (state, val) { state.POSION = val },
  SET_DEPARTFLAG (state, val) { state.DEPARTFLAG = val }


}
