import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import {setNowMonth2} from "@/utils/time";
const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/login/login.vue')
  },
  {
    path: '/manage',
    name: 'Main',
    component: () => import('../views/main.vue'),
  },
  {
    path: '/jkindex',
    name: 'Jkindex',
    component: () => import('../views/cream/index.vue')
  },
  {
    path: '/forms',
    name: 'Forms',
    component: () => import('../views/procoll/forms/forms.vue')
  },
  {
    path: '/selforms',
    name: 'Selforms',
    component: () => import('../views/procoll/sel/selforms.vue')
  },
  {
    path: '/census',
    name: 'Census',
    component: () => import('../views/procoll/census/census')
  },
  {
    path: '/backform',
    name: 'Backform',
    component: () => import('../views/procoll/back/back')
  },
  {
    path: '/adduser',
    name: 'Adduser',
    component: () => import('../views/admin/adduser.vue')
  },
  {
    path: '/auto',
    name: 'Auto',
    component: () => import('../views/cream/autovideo.vue')
  },
  {
    path: '/mass',
    name: 'Mass',
    component: () => import('../views/cream/mass.vue')
  },
  {
    path: '/weekplan',
    name: 'Weekplan',
    component: () => import('../views/imd/weekplan/weekplan')
  },
  {
    path: '/addgrade',
    name: 'Addgrade',
    component: () => import('../views/imd/grade/addgrade')
  },
  {
    path: '/ydsuccess',
    name: 'Ydsuccess',
    component: () => import('../views/imd/grade/addsuccess')
  },
  {
    path: '/grade',
    name: 'Grade',
    component: () => import('../views/imd/grade/grade')
  },
  {
    path: '/addqr',
    name: 'Addqr',
    component: () => import('../views/bdstqr/addqr/addqr')
  },
  {
    path: '/selqr',
    name: 'Selqr',
    component: () => import('../views/bdstqr/selqr/selqr')
  },
  {
    path: '/mobilsel',
    name: 'Mobilsel',
    component: () => import('../views/bdstqr/selqr/mobilsel'),
    meta:{
      title: '扫描内容'
    }
  },
  {
    path: '/engup',
    name: 'Engup',
    component: () => import('../views/fileupdown/engup/engup')
  },
  {
    path: '/engdown',
    name: 'Engdown',
    component: () => import('../views/fileupdown/engdown/engdown')
  },
  {
    path: '/imdup',
    name: 'Imdup',
    component: () => import('../views/fileupdown/imdup/imdup')
  },
  {
    path: '/imdown',
    name: 'Imdown',
    component: () => import('../views/fileupdown/imdown/imdwon')
  },
  {
    path: '/seldown',
    name: 'Seldown',
    component: () => import('../views/fileupdown/seldown/seldown')
  },
  {
    path: '/planaudit',
    name: 'Planaudit',
    component: () => import('../views/imd/weekplan/planaudit')
  },
  {
    path: '/testiframe',
    name: 'Testiframe',
    component: () => import('../views/test/testiframe')
  },
  {
    path: '/teste',
    name: 'Teste',
    component: () => import('../views/test/testseamless')
  },
  {
    path: '/testj',
    name: 'Testtj',
    component: () => import('../views/test/testtj')
  },
  {
    path: '/workers',
    name: 'Workers',
    component: () => import('../views/worker/workers')
  },
  {
    path: '/worker_show',
    name: 'Worker_show',
    component: () => import('../views/worker/worker_show')
  },
  {
    path: '/bigshow',
    name: 'Bigshow',
    component: () => import('../views/worker/bigshow')
  },
  {
    path: '/testecharts',
    name: 'Testecharts',
    component: () => import('../views/test/testecharts')
  },
  {
    path: '/testimport',
    name: 'Testimport',
    component: () => import('../views/test/testimport')
  },
  {
    path: '/mtzjss',
    name: 'Mtzjss',
    component: () => import('../views/ruan/mtzjss')
  },
  {
    path: '/oper',
    name: 'Oper',
    component: () => import('../views/oper/oper')
  },
  {
    path: '/testscanf',
    name: 'testscanf',
    component: () => import('../views/login/scanf')
  },
  {
    path: '/headst',
    name: 'question',
    component: () => import('../views/headquart/phone/question')
  },
  {
    path: '/headstgrade',
    name: 'Headstgrade',
    component: () => import('../views/headquart/web/StGrade')
  },
  {
    path: '/salarypost',
    name: 'Salarypost',
    component: () => import ('../views/salary_post/salarypost')
  },
  {
    path: '/show_salary',
    name: 'Show_salary',
    component: () => import ('../views/salary_post/show_salary')
  },
  {
    path: '/transfer',
    name: 'Transfer',
    component: () => import ('../views/economy/transfer')
  },
  {
    path: '/show_progess',
    name: 'Show_progess',
    component: () => import ('../views/show/show_pro_befor/show_progess')
  },
  {
    path: '/show_alone',
    name: 'Show_alone',
    component: () => import ('../views/show/show_pro_befor/show_alone')
  },
  {
    path: '/show_chaowa_alone',
    name: 'Show_chaowa_alone',
    component: () => import ('../views/show/show_pro_befor/show_chaowa_alone')
  },
  {
    path: '/show_hunnum_alone',
    name: 'Show_hunnum_alone',
    component: () => import ('../views/show/show_pro_befor/show_hunnum_alone')
  },
  {
    path: '/show_sdgxnum_alone',
    name: 'Show_sdgxnum_alone',
    component: () => import ('../views/show/show_pro_befor/show_sdgxnum_alone')
  },
  {
    path: '/testshow',
    name: 'Testshow',
    component: () => import ('../views/show/show_pro_befor/test')
  },
  {
    path: '/qiticheck',
    name: 'Qiticheck',
    component: () => import ('../views/qiti/qiticheck')
  },
  {
    path: '/transferNotoken',
    name: 'Transfernotoken',
    component: () => import ('../views/economy/transfer_notoken')
  },
  {
    path: '/meetting',
    name: 'Meetting',
    component: () => import ('../views/imd/meet/meetting')
  },
  {
    path: '/kjkshow',
    name: 'Kjkshow',
    component: () => import ('../views/qiti/show/kjkshow')
  },
  {
    path: '/erXshow',
    name: 'erXshow',
    component: () => import ('../views/qiti/show/erXshow')
  },
  {
    path: '/KdOnePdshow',
    name: 'KdOnePdshow',
    component: () => import ('../views/qiti/show/KdOnePdshow')
  },
  {
    path: '/KdOneJkshow',
    name: 'KdOneJkshow',
    component: () => import ('../views/qiti/show/KdOneJkshow')
  },
  {
    path: '/KdJkshow',
    name: 'KdJkshow',
    component: () => import ('../views/qiti/show/KdJkshow')
  },
  {
    path: '/show_finace',
    name: 'Show_finace',
    component: () => import ('../views/show/show_finace/show_finace')
  },
  {
    path: '/show_material',
    name: 'Show_material',
    component: () => import ('../views/show/show_material/show_material')
  },
  {
    path: '/synremp',
    name: 'Synremp',
    component: () => import ('../views/procoll/synremp/synremp')
  },
  {
    path: '/index',
    name: 'Test',
    component: () => import ('../views/test/test')
  },
  {
    path: '/expert_index',
    name: 'Expert_index',
    component: () => import ('../views/expert_consult/index.vue')
  },
  {
    path: '/expert_info',
    name: 'Expert_info',
    component: () => import ('../views/expert_consult/expert_info/expert_info.vue')
  },
  {
    path: '/answer_all_questions',
    name: 'Answer_all_questions',
    component: () => import ('../views/expert_consult/answer_all_questions/index.vue')
  },
  {
    path: '/answer_detail',
    name: 'Answer_detail',
    component: () => import ('../views/expert_consult/answer_detail/answer_detail')
  },
  {
    path: '/addExpert',
    name: 'AddExpert',
    component: () => import ('../views/expert_consult/assembly/addExpert')
  },
  {
    path: '/search_question',
    name: 'Search_question',
    component: () => import ('../views/expert_consult/answer_all_questions/search_question')
  },
  {
    path: '/tele_meet_detail',
    name: 'Tele_meet_detail',
    component: () => import ('../views/expert_consult/tele_meet/tele_meet_detail')
  },
  {
    path: '/file_detail',
    name: 'File_detail',
    component: () => import ('../views/expert_consult/res_sha/file_detail')
  },
  {
    path: '/rock_index',
    name: 'Rock_index',
    component: () => import ('../views/rock/index')
  },
  {
    path: '/salaryup',
    name: 'Salaryup',
    component: () => import ('../views/salary_post/salaryup')
  },
  {
    path: '/salarysel',
    name: 'Salarysel',
    component: () => import ('../views/salary_post/salarysel')
  },
  {
    path: '/salaryuser',
    name: 'Salaryuser',
    component: () => import ('../views/salary_post/salaryuser')
  },
  {
    path: '/salarySendHistory',
    name: 'SalarySendHistory',
    component: () => import ('../views/salary_post/salarySendHistory')
  },
  {
    path: '/add_meet',
    name: 'Add_meet',
    component: () => import ('../views/imd/meet/add_meet')
  },
  {
    path: '/manage_meet',
    name: 'Manage_meet',
    component: () => import ('../views/imd/meet/manage_meet')
  },
  {
    path: '/addMeals',
    name: 'addMeals',
    component: () => import ('../views/headquart/newspapermeals/addMeals')
  },
  {
    path: '/procoll_comparison',
    name: 'procoll_comparison',
    component: () => import ('../views/procoll/synremp/procoll_comparison')
  },
  {
    path: '/online_document',
    name: 'Online_document',
    component: () => import ('../views/online_document/manage/online_document')
  },
  {
    path: '/view_online',
    name: 'View_online',
    component: () => import ('../views/online_document/view_online_document/view_online')
  },
  {
    path: '/pro_online_document',
    name: 'Pro_online_document',
    component: () => import ('../views/procoll/online_document/online_docu_bdst/pro_online_manage')
  },
  {
    path: '/online_docu_manage',
    name: 'Online_docu_manage',
    component: () => import ('../views/procoll/online_document/online_docu_manage/pro_online_date')
  },
  {
    path: '/auto',
    name: 'Auto',
    component: () => import ('../views/online_document/auto/auto')
  },
  {
    path: '/material',
    name: 'Material',
    component: () => import ('../views/material/material/material')
  },
  {
    path: '/material_ware_issue',
    name: 'Material_ware_issue',
    component: () => import ('../views/material/ware_issue/material_ware_issue')
  },
  {
    path: '/material_manage',
    name: 'Material_manage',
    component: () => import ('../views/material/admin/material_manage')
  },
  {
    path: '/material_data',
    name: 'Material_data',
    component: () => import ('../views/material/material_data/material_data')
  },
  {
    path: '/material_test',
    name: 'Material_test',
    component: () => import ('../views/material/test')
  },
  {
    path: '/material_num_show',
    name: 'Material_num_show',
    component: () => import ('../views/material/material_data/material_num_show')
  },
  {
    path: '/get_peo_num',
    name: 'Get_peo_num',
    component: () => import ('../views/imd/food/get_peo_num'),
    meta:{
      title: '用餐人数统计'
    }
  },
  {
    path: '/up_food_peonum',
    name: 'Up_food_peonum',
    component: () => import ('../views/imd/food/up_food_peonum'),
    meta:{
      title: '用餐上报'
    }
  },
  {
    path: '/yz_tel',
    name: 'Yz_tel',
    component: () => import ('../views/imd/food/yz_tel'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/food_sned_message',
    name: 'Food_sned_message',
    component: () => import ('../views/imd/food/food_send_message'),
    meta:{
      title: '绑定报餐消息推送'
    }
  },
  {
    path: '/cook_book',
    name: 'Cook_book',
    component: () => import ('../views/imd/food/cookbook/cookbook')
  },
  {
    path: '/wx_bang_tel',
    name: 'Wx_bang_tel',
    component: () => import ('../views/imd/wx_bang_tel'),
    meta:{
      title: '验证手机号'
    }
  },
  {
    path: '/wx_openid_bang',
    name: 'Wx_openid_bang',
    component: () => import ('../views/imd/wx_openid_bang'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/yz_openid',
    name: 'Yz_openid',
    component: () => import ('../views/imd/food/yz_openid'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/mobile_show_food',
    name: 'Mobile_show_food',
    component: () => import ('../views/imd/food/cookbook/mobile_show_food'),
    meta:{
      title: '食谱'
    }
  },
  {
    path: '/mobile_yz_food',
    name: 'Mobile_yz_food',
    component: () => import ('../views/imd/food/cookbook/mobile_yz_food'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/wx_bang_tel_exam',
    name: 'Wx_bang_tel_exam',
    component: () => import ('../views/imd/wx_message_exam/wx_bang_tel_exam'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/wx_message_exam',
    name: 'Wx_message_exam',
    component: () => import ('../views/imd/wx_message_exam/wx_message_exam'),
    meta:{
      title: '绑定'
    }
  },
  {
    path: '/file_dispose',
    name: 'File_dispose',
    component: () => import ('../views/online_document/dispose/file_dispose')
  },
  {
    path: '/equiment_manage',
    name: 'Equiment_manage',
    component: () => import ('../views/equiment/equiment_manage/manage')
  },
  {
    path: '/equiment_ware_issue',
    name: 'Equiment_ware_issue',
    component: () => import ('../views/equiment/equiment_ware_issue/equiment_ware_issue')
  },
  {
    path: '/map_fxy',
    name: 'Map_fxy',
    component: () => import ('../views/risk_map/index/index')
  },
  {
    path: '/safe_manage',
    name: 'Safe_manage',
    component: () => import ('../views/risk_map/safe/manage/index')
  },
  {
    path: '/map_test',
    name: 'Map_test',
    component: () => import ('../views/risk_map/test')
  },
  {
    path: '/exam_admin',
    name: 'Exam_admin',
    component: () => import ('../views/exam/exam_admin/admin')
  },
  {
    path: '/pro_wx_bang_tel',
    name: 'Pro_wx_bang_tel',
    component: () => import ('../views/imd/pro_bang/wx_bang_tel'),
    meta:{
      title: '验证手机号'
    }
  },
  {
    path: '/pro_wx_openid_bang',
    name: 'Pro_wx_openid_bang',
    component: () => import ('../views/imd/pro_bang/wx_openid_bang'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/dbd_index',
    name: 'Dbd_index',
    component: () => import ('../views/material/dbd/index/dbd_index'),
  },
  {
    path: '/equiment_dbd_index',
    name: 'Equiment_dbd_index',
    component: () => import ('../views/equiment/dbd/index/dbd_index'),
  },
  {
    path: '/material_pending',
    name: 'Material_pending',
    component: () => import ('../views/material/dbd/phone/pending'),
    meta:{
      title: '物资调拨单-待处理'
    }
  },
  {
    path: '/material_dbd_detail',
    name: 'Material_dbd_detail',
    component: () => import ('../views/material/dbd/phone/detail'),
    meta:{
      title: '物资调拨单-详情'
    }
  },
  {
    path: '/dbd_bang',
    name: 'Dbd_bang',
    component: () => import ('../views/material/dbd/bang/dbd_bang'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/dbd_bang_tel',
    name: 'Dbd_bang_tel',
    component: () => import ('../views/material/dbd/bang/dbd_bang_tel'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/dbd_yz',
    name: 'Dbd_yz',
    component: () => import ('../views/material/dbd/bang/dbd_yz'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/phone_add_meet',
    name: 'Phone_add_meet',
    component: () => import ('../views/imd/meet/phone/add_meet.vue'),
    meta:{
      title: '添加会议'
    }
  },
  {
    path: '/phone_add_meet_yz',
    name: 'Phone_add_meet_yz',
    component: () => import ('../views/imd/meet/phone/add_meet_yz.vue'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/sys_index',
    name: 'Sys_index',
    component: () => import ('../views/sys/admin/index.vue')
  },
  {
    path: '/sys_work',
    name: 'Sys_work',
    component: () => import ('../views/sys/sys_work/index.vue')
  },
  {
    path: '/sys_show',
    name: 'Sys_show',
    component: () => import ('../views/sys/sys_show/sys_show.vue')
  },
  {
    path: '/sys_show_data',
    name: 'Sys_show_data',
    component: () => import ('../views/sys/sys_show/sys_show_data.vue')
  },
  {
    path: '/sys_bang',
    name: 'Sys_bang',
    component: () => import ('../views/sys/bang/sys_bang.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/sys_bang_tel',
    name: 'Sys_bang_tel',
    component: () => import ('../views/sys/bang/sys_bang_tel.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/sys_yz',
    name: 'Sys_yz',
    component: () => import ('../views/sys/bang/sys_yz.vue'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/sys_clock_detail',
    name: 'Clock_detail',
    component: () => import ('../views/sys/phone/clock_detail.vue'),
    meta:{
      title: '打卡详情'
    }
  },
  {
    path: '/sys_clock_index',
    name: 'Clock_index',
    component: () => import ('../views/sys/phone/clock_index.vue'),
    meta:{
      title: '打卡列表'
    }
  },
  {
    path: '/quantitie_index',
    name: 'Quantitie_index',
    component: () => import ('../views/quantitie/index.vue')
  },
  {
    path: '/scheme_index',
    name: 'Scheme_index',
    component: () => import ('../views/scheme/index.vue')
  },
  {
    path: '/scheme_bang',
    name: 'Scheme_bang',
    component: () => import ('../views/scheme/bang/scheme_bang.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/scheme_bang_tel',
    name: 'Scheme_bang_tel',
    component: () => import ('../views/scheme/bang/scheme_bang_tel.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/wage_index',
    name: 'Wage_index',
    component: () => import ('../views/salary_post/new_post/wage_index.vue')
  },
  {
    path: '/kaoqin_index',
    name: 'Kaoqin_index',
    component: () => import ('../views/salary_post/new_post/kaoqin/kaoqin_index.vue')
  },
  {
    path: '/phone_kaoqin',
    name: 'Phone_kaoqin',
    component: () => import ('../views/salary_post/new_post/kaoqin/phone_kaoqin.vue')
  },
  {
    path: '/phone_openid',
    name: 'Phone_openid',
    component: () => import ('../views/salary_post/new_post/kaoqin/phone_openid.vue')
  },
  {
    path: '/sys_num_statistics',
    name: 'Sys_num_statistics',
    component: () => import ('../views/sys/sys_num_statistics/index.vue')
  },
  {
    path: '/five_bang',
    name: 'Five_bang',
    component: () => import ('../views/procoll/five/five_bang.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/five_bang_tel',
    name: 'Five_bang_tel',
    component: () => import ('../views/procoll/five/five_bang_tel.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/five_yz',
    name: 'Five_yz',
    component: () => import ('../views/procoll/five/five_yz.vue'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/five_index',
    name: 'Five_index',
    component: () => import ('../views/procoll/five/index/index.vue')
  },
  {
    path: '/waste_residue',
    name: 'Waste_residue',
    component: () => import ('../views/material/waste_residue/waste_residue.vue')
  },
  {
    path: '/car_ft',
    name: 'Car_ft',
    component: () => import ('../views/procoll/car/car_ft.vue'),
    meta:{
      title: '车辆信息录入'
    }
  },
  {
    path: '/ft_bang',
    name: 'Ft_bang',
    component: () => import ('../views/procoll/car/ft_bang.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/ft_bang_tel',
    name: 'Ft_bang_tel',
    component: () => import ('../views/procoll/car/ft_bang_tel.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/ft_yz',
    name: 'Ft_yz',
    component: () => import ('../views/procoll/car/ft_yz.vue'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/material_nodal',
    name: 'Material_nodal',
    component: () => import ('../views/material/material_nodal/index.vue')
  },
  {
    path: '/xfxh',
    name: '/XfXh',
    component: () => import ('../views/test/XfXh.vue'),
    meta:{
      title: 'AI回答'
    }
  },
  {
    path: '/peo_gps',
    name: 'Peo_gps',
    component: () => import ('../views/peo_gps/peo_gps_index.vue'),
    meta:{
      title: '人员定位管理'
    }
  },
  {
    path: '/peo_gps_data_show',
    name: 'Peo_gps_data_show',
    component: () => import ('../views/peo_gps/data_show.vue'),
    meta:{
      title: '人员进出洞数据展示'
    }
  },
  {
    path: '/big_screen_show',
    name: 'Big_screen_show',
    component: () => import ('../views/big_screen_show/index.vue')
  },
  {
    path: '/loan_index',
    name: 'Loan_index',
    component: () => import ('../views/loan/loan_index.vue'),
    meta:{
      title: '借款信息'
    }
  },
  {
    path: '/loan_main',
    name: 'Loan_main',
    component: () => import ('../views/loan/loan_main.vue'),
    meta:{
      title: '借款信息'
    }
  },
  {
    path: '/loan_repayment',
    name: 'Loan_repayment',
    component: () => import ('../views/loan/loan_repayment.vue'),
    meta:{
      title: '还款信息'
    }
  },
  {
    path: '/loan_bang',
    name: 'Loan_bang',
    component: () => import ('../views/loan/bang/loan_bang.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/loan_bang_tel',
    name: 'Loan_bang_tel',
    component: () => import ('../views/loan/bang/loan_bang_tel.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/loan_yz',
    name: 'Loan_yz',
    component: () => import ('../views/loan/bang/loan_yz.vue'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/material_turnover',
    name: 'Material_turnover',
    component: () => import ('../views/material/turnover/index.vue'),
    meta:{
      title: '物资-材料周转'
    }
  },
  {
    path: '/new_material_turnover',
    name: 'New_material_turnover',
    component: () => import ('../views/material/turnover/new_material_turnover.vue'),
    meta:{
      title: '物资-新增材料周转'
    }
  },
  {
    path: '/select_material_turnover',
    name: 'Select_material_turnover',
    component: () => import ('../views/material/turnover/select_material_turnover.vue'),
    meta:{
      title: '物资-查询材料周转'
    }
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import ('../views/material/turnover/error.vue'),
    meta:{
      title: '错误页面'
    }
  },
  {
    path: '/turnover_bang',
    name: 'Turnover_bang',
    component: () => import ('../views/material/turnover/bang/turnover_bang.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/turnover_bang_tel',
    name: 'Turnover_bang_tel',
    component: () => import ('../views/material/turnover/bang/turnover_bang_tel.vue'),
    meta:{
      title: '绑定系统'
    }
  },
  {
    path: '/turnover_yz',
    name: 'Turnover_yz',
    component: () => import ('../views/material/turnover/bang/turnover_yz.vue'),
    meta:{
      title: '验证'
    }
  },
  {
    path: '/car_gps',
    name: 'Car_gps',
    component: () => import ('../views/car_gps/car_gps.vue'),
  },
  {
    path: '/test_three',
    name: 'Test_three',
    component: () => import ('../views/test/test_three.vue'),
  },
  {
    path: '/signature',
    name: 'Signature',
    component: () => import ('../views/imd/esign/signature.vue'),
  },
  {
    path: '/file_index',
    name: 'File_index',
    component: () => import ('../views/imd/esign/file_index.vue'),
  },
  {
    path: '/signature_success',
    name: 'Signature_success',
    component: () => import ('../views/imd/esign/signature_success.vue'),
  },
  {
    path: '/signature_test',
    name: 'Signature_test',
    component: () => import ('../views/imd/esign/test.vue'),
  },




]

const router = createRouter({
  history: createWebHistory(),
  routes
})
export default router
